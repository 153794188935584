import { Box, List, ListItem, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink, useNavigate } from "react-router-dom";
import { setCheckedApps, setSelectAppOption, setSelectedApps, } from "slices/selectedAppVer";
import { useAppDispatch } from "store/hooks";

const ListWrapper = styled(Box)(
    ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 30px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
    const history = useNavigate();
    const dispatch = useAppDispatch();

    const handleNavigation = (route: string) => {
        const localStorageFilterState = localStorage.getItem('filter-state');
        const storedSearchString = localStorageFilterState ? JSON.parse(localStorageFilterState) : null;
        if (storedSearchString != null && storedSearchString.AppVer) {
            dispatch(setCheckedApps(storedSearchString.AppVer.checkedApps ?? []));
            dispatch(setSelectedApps(storedSearchString.AppVer.selectedApps ?? []));
            if (storedSearchString.AppVer.checkedApps === undefined || storedSearchString.AppVer.checkedApps.length === 0) {
                dispatch(setSelectedApps(["All Applications"]));
                dispatch(setSelectAppOption("Select All"));
            } else {
                dispatch(setSelectedApps(storedSearchString.AppVer.selectedApps));
                dispatch(setSelectAppOption("Select None"));
            }
        }

        // Navigate to the desired route
        history(route);
    };

    return (
        <>
            <ListWrapper>
                <List disablePadding component={Box} display="flex">
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        component={NavLink}
                        to="/dashboard"
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary="Dashboard"
                        />
                    </ListItem>
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        component={NavLink}
                        to="Crashes"
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary="Crashes"
                        />
                    </ListItem>
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        onClick={() => handleNavigation('/Buckets')}
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary="Buckets"
                        />
                    </ListItem>
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        component={NavLink}
                        to="Archives"
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary="Archives"
                        />
                    </ListItem>
                </List>
            </ListWrapper>
        </>
    );
}

export default HeaderMenu;
