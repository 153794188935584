import { ArchiveStatus, Priority } from "./crashRequestDto";

export default interface bucketInfo {
	bucketId: number;
	firstCrashId: number;
	crashCount: number;
	countOfSimilarCrash: number;
	countOfUniqueUser: number;
	archiveStatus: ArchiveStatus
}

export default interface bucketedCrashInfo {
	crashId: number;
	cumulativeDistanceFromOtherCrashes: number;
	applicationId: number;
	applicationName: string;
	installationIdentifier: string;
	applicationVersion: string;
    platform: string;
    osName: string;
    osVersion: string;
    osBuildNumber: string;
    architecture: string;
    exceptionCode: string;
    countOfUniqueUser: number;
    lastOccurrence: string;
	timestamp: string;
	dumpFileURL: string;
}

export enum BucketTableSortField {
	CrashCount = 0,
	CountOfSimilarCrash = 1,
	CountOfUniqueUser = 2,
	BucketId = 3
}

export enum BucketedCrashTableSortField {
	ApplicationVersion = 0,
	CrashId = 2,
	Timestamp = 6
}