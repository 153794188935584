import { Box, Button, Divider, List, ListItem, styled } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
.MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
    padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
}

    .MuiListSubheader-root {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.colors.alpha.trueWhite[50]};
    padding: ${theme.spacing(0, 2.5)};
    line-height: 1.4;
    }
`
);

const Sidebar = (props: any) => {
  const closeSidebar = () => {
    props.setSidebar(false);
  };

  return (
    <div>
      <MenuWrapper>
        <List component="div">
          <Divider orientation="horizontal" />
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/dashboard"
              sx={{
                color: "#000",
              }}
            >
              Dashboard
            </Button>
          </ListItem>
          <Divider orientation="horizontal" />
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/crashes"
              sx={{
                color: "#000",
              }}
            >
              Crashes
            </Button>
          </ListItem>
          <Divider orientation="horizontal" />
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/buckets"
              sx={{
                color: "#000",
              }}
            >
              Buckets
            </Button>
          </ListItem>
          <Divider orientation="horizontal" />
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/archives"
              sx={{
                color: "#000",
              }}
            >
              Archive
            </Button>
          </ListItem>
        </List>
      </MenuWrapper>
    </div>
  );
};

export default Sidebar;
