import {deleteWithAuth, getWithAuth, postWithAuth, putWithAuth} from "utilities/api";
import bucketRequestParams from "dto/bucketRequestDto";
import { BucketedCrashTableSortField } from "src/dto/bucketInfoDto";

export async function getBucketInfo(accessToken: string,
                                    params: bucketRequestParams,
                                    signal: AbortSignal | null = null) {
    const url = window.env.baseURL + 'Bucket/';
    return postWithAuth(url, accessToken, params, signal).then((response) => {
        return response;
    });
}

export async function getBucketInfoById(accessToken: string, bucketId: number) {
    const url = window.env.baseURL + `Bucket/${bucketId}/details`;
    return getWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function getBucketedCrashesInfo(accessToken: string,
                                             bucketId: number,
                                             currentPageNumber: number = 1,
                                             itemPerPage: number = 15,
                                             sortField: BucketedCrashTableSortField, 
                                             isAscendingOrder: boolean = false) {
    const url = window.env.baseURL + `Bucket/${bucketId}?&currentPageNumber=${currentPageNumber}&itemPerPage=${itemPerPage}
    &sortField=${sortField}&isAscending=${isAscendingOrder}`;
    return getWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function clearRebucketQueue(accessToken: string) {
    const url = window.env.baseURL + `bucket/clearrebucketqueue`;
    return deleteWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function putAllToBucketQueue(accessToken: string,
                                          maximumCrashToBucket: number) {
    const url = window.env.baseURL + `bucket/bucketall?&maximumCrashToBucket=${maximumCrashToBucket}`;
    return putWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function getRebucketSummary(accessToken: string) {
    const url = window.env.baseURL + "Bucket/summary";
    return getWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function getFailedRebucketingInfo(accessToken: string,
    currentPageNumber: number = 1,
    itemPerPage: number = 15
) {
    var url = window.env.baseURL + `bucket/failed-rebucketing/info?currentPageNumber=${currentPageNumber}&itemPerPage=${itemPerPage}`;
    return getWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function clearFailedRebucketing(accessToken: string, crashId: number) {
    const url = window.env.baseURL + `bucket/remove-fail-rebucketing/${crashId}`;
    return deleteWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function reRebucketCrash(accessToken: string, id: number) {
	const url = window.env.baseURL + "bucket/re-rebucket/" + id;
	return getWithAuth(url, accessToken).then((response) => {
		return response;
	});
}
